import { render, staticRenderFns } from "./UpdateNotice.vue?vue&type=template&id=adff4eae&scoped=true"
import script from "./UpdateNotice.vue?vue&type=script&lang=js"
export * from "./UpdateNotice.vue?vue&type=script&lang=js"
import style0 from "./UpdateNotice.vue?vue&type=style&index=0&id=adff4eae&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "adff4eae",
  null
  
)

export default component.exports