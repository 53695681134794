<template>
  <div id="app" :class="{themeSelected:true}">
    <router-view />
  </div>
</template>

<script>
import { getStorage } from '@/utils/storage'

export default {
  name: 'App',
  data () {
    return {
      theme: '',
      themeSelected: null
    }
  },
  watch: {
    theme () {
      this.themeSelected = require(`./assets/theme/${this.theme}.less`)
    }
  },
  created () {
    // 检测当前页面的版本号， 是否与服务器版本号一致， 否则强制刷新页面
    const currentVersion = require('../package.json').version
    const localVersion = localStorage.getItem('oohforce-workbench')

    if (localVersion) { // 判断是否和当前版本一致
      if (localVersion !== currentVersion) {
        localStorage.setItem('oohforce-workbench', currentVersion)
        window.location.reload(true) // 强制刷新页面
      }
    } else {
      localStorage.setItem('oohforce-workbench', currentVersion)
      window.location.reload(true) // 强制刷新页面
    }

    this.theme = getStorage('oohforce-workbench-theme') || 'dark'
  },
  methods: {
    loadTheme () {
      // 获取并设置当前页面的主题
      const themeStyle = getStorage('oohforce-workbench-theme') || 'dark'
      if (this.theme !== themeStyle) {
        // this.theme = themeStyle
        window.location.reload()
      }
    }
  }
}
</script>
