import request from '@/utils/requestV2'
const qs = require('qs')

// 删除单/多个文件
export function deleteFiles (data) {
  return request({
    url: '/ooh-sys/v1/file/deletefiles',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取文件集合
export function getFileList (data) {
  return request({
    url: '/ooh-sys/v1/file/getfilelist',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取更新公告集合
export function getNewest (data) {
  return request({
    url: '/ooh-sys/v1/updateinfo/getnewest',
    method: 'post',
    data: qs.stringify(data)
  })
}
