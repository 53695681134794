import request from '@/utils/requestV2'
const qs = require('qs')

export function saveinstance (data) {
  return request({
    url: '/ooh-sys/v1/event/saveinstance',
    method: 'post',
    data: qs.stringify(data)
  })
}
