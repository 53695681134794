/**
 *
 * @param {String} tokenKey
 * @param {Boolean} showAllData //显示所有数据，默认不包含过期时间
 */
export function getStorage (tokenKey, showAllData = false) {
  var data = localStorage.getItem(tokenKey)
  try {
    if (data != null) {
      var dataObj = JSON.parse(data)
      // if (dataObj.expirse && new Date().getTime() - dataObj.expirse > 0) {
      //   removeStorage(tokenKey)
      // } else {
      return showAllData ? dataObj : dataObj.data
      // }
    } else {
      return null
    }
  } catch (error) {
    return null
  }
}

export function setStorage (tokenKey, value, seconds) {
  var curTime = new Date()
  var expirseTime = curTime.setTime(curTime.getTime() + (seconds * 1000))
  return localStorage.setItem(tokenKey, JSON.stringify({ data: value, expirse: expirseTime }))
}

export function removeStorage (tokenKey) {
  return localStorage.removeItem(tokenKey)
}
