import Vue from 'vue'
import Vuex from 'vuex'
import userModule from './modules/user_v2'
import sysModule from './modules/sys'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user: userModule,
    sys: sysModule
  }
})
