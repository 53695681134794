import request from '@/utils/requestV2'
const qs = require('qs')

// 获取租户配置
export function getPublisherSetting (data) {
  return request({
    url: '/ooh-os/v1/company/getsetting',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function getCompanyPage (data) {
  return request({
    url: '/ooh-os/v1/company/getcompanypage',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取所有公司
 * @param {Object} data
 */
export function getCompanyList (data) {
  return request({
    url: '/ooh-os/v1/company/getcompanylist',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取公司对应的结算方集合
 * @param {Object} data
 */
export function getDispatcherList (data) {
  return request({
    url: '/ooh-os/v1/company/getdispatcherlist',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取派单方对应的供应商集合
 * @param {Object} data
 */
export function getSupplierCompany (data) {
  return request({
    url: '/ooh-os/v1/company/getsuppliercompany',
    method: 'post',
    data: qs.stringify(data)
  })
}
/**
 * 获取公司对应的交易方、运维方、结算方集合
 * @param {Object} data
 */
export function getcompanylistbytype (data) {
  return request({
    url: '/ooh-os/v1/company/getcompanylistbytype',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * 获取公司详情
 * @param {Object} data
 */
export function getCompany (data) {
  return request({
    url: '/ooh-os/v1/company/getcompany',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 获取公司下属公司
export function getCompanyChild (data) {
  return request({
    url: '/ooh-os/v1/company/getcompanychild',
    method: 'post',
    data: qs.stringify(data)
  })
}
