<style lang="less" scoped>
@import "../../assets/css/workplatform3.css";

.menu-item {
  /* background: #25272a; */
  height: calc(100vh - 190px);
  overflow-y: scroll;
  scrollbar-width: none;
  /* FireFox */
  -ms-overflow-style: none;
  /*IE10+*/
}

.menu-item::-webkit-scrollbar {
  display: none;
}

.ivu-menu-vertical .ivu-menu-item,
.ivu-menu-vertical .ivu-menu-submenu-title {
  padding: 8px 0;
  font-size: 14px;
  /* text-align: center; */
}

.menu-item span {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: bottom;
  transition: width 0.2s ease 0.2s;
  font-size: 14px;
}

.menu-item i {
  transform: translateX(0px);
  transition: font-size 0.2s ease, transform 0.2s ease;
  vertical-align: middle;
  font-size: 14px;
}

.menu-item /deep/ .ivu-menu-submenu-title {
  padding: 10px 5px 10px 16px;
}

.menu-item /deep/ .ivu-menu-submenu-title-icon {
  right: 0px;
}

.ivu-dropdown-item:hover {
  background: #40434a;
  color: #fff;
  opacity: 0.9;
}

.platform-submenu {
  width: 48px;
}

.icon-btn {
  cursor: pointer;
}

.icon-btn:hover {
  color: #2b85e4;
}

.platform {
  display: block;
  padding: 10px 0;
  /* color: #fff; */
  border-radius: 3px;
}

.platform:hover {
  color: #5cadff;
  border-color: #5cadff;
}

.platform-actived {
  color: #2d8cf0;
  border-color: #2b85e4;
}
</style>
<template>
  <div id="content_body">
    <Layout :style="{ minHeight: '100vh' }">
      <Sider
        width="120"
        style="z-index: 999;"
      >
        <div>

          <div class="logo">
            <img
              v-if="defaultPublisherId === 13"
              :src="require('../../assets/images/qddt_'+theme+'.png')"
            >
            <img
              v-else-if="defaultPublisherId === 18"
              :src="require('../../assets/images/bjdtzy_'+theme+'.png')"
            >
            <img
              v-else-if="defaultPublisherId === 21"
              :src="require('../../assets/images/bjgd_'+theme+'.png')"
            >
            <img
              v-else
              :src="require('../../assets/images/ooh_'+theme+'.png')"
            >
          </div>
          <Row style="padding: 12px 5px 12px 16px;">
            <i-col span="18">
              <h2>{{ platformMenu.platformName }}</h2>
            </i-col>
            <i-col
              span="6"
              class="text-right"
            >
              <Poptip
                v-show="platformMenuArray&&platformMenuArray.length>1"
                trigger="hover"
                placement="right"
                transfer
              >
                <Icon
                  size="24"
                  type="md-apps"
                  class="icon-btn"
                />
                <div
                  slot="content"
                  class="menu-change-platform"
                >
                  <Row :gutter="8">
                    <i-col
                      span="12"
                      class="m-b-5"
                      v-for="item in platformMenuArray"
                      :key="'p_'+item.platformId"
                    >
                      <a
                        class="text-center platform"
                        :class="platformMenu.platformId===item.platformId?'platform-actived':''"
                        href="javascript:void(0)"
                        @click="handleSwitchPlatform(item)"
                      >
                        <h2>{{item.platformName}}</h2>
                      </a>
                    </i-col>
                  </Row>
                </div>
              </Poptip>
            </i-col>
          </Row>
          <Menu
            ref="menu_box"
            id="menu_box"
            class="menu-item"
            :active-name="defalutMenu"
            :open-names="openNames"
            :theme="menuTheme"
            accordion
            width="auto"
            @on-select="handleChangeMenu"
            v-if="menuList[0].meta.group !== -1"
          >
            <div
              v-for="item in menuList"
              :key="item.group"
            >
              <template v-if="item.meta.category===4">
                <MenuItem
                  v-for="child in item.children"
                  :key="'child_' + child.meta.menuId"
                  :name="child.meta.menuId"
                  :to="child.path"
                  class="ivu-menu-submenu-title"
                >{{ child.meta.title }}</MenuItem>
              </template>
              <Submenu
                v-else
                :name="item.children[0].meta.group"
              >
                <span slot="title">
                  {{ item.meta ? item.meta.title : '/' }}
                </span>
                <MenuItem
                  v-for="child in item.children"
                  :key="'child_' + child.meta.menuId"
                  :name="child.meta.menuId"
                  :to="child.path"
                  style="padding-left: 25px"
                >{{ child.meta.title }}</MenuItem>
              </Submenu>
            </div>
          </Menu>
        </div>

        <div class="menu-footer">
          <Row>
            <i-col
              v-if="publisherArray.length>1"
              span="8"
            >
              <Tooltip
                content="切换租户"
                placement="top-start"
                class="workplatform-lock"
              >
                <Icon
                  size="24"
                  type="md-git-network"
                  class="workplatform-lock-icon"
                  @click="switchPublisherModal = true"
                />
              </Tooltip>
            </i-col>
            <i-col :span="(publisherArray.length>1?8:12)">
              <div class="workplatform-lock">
                <Poptip
                  trigger="hover"
                  placement="top-start"
                >
                  <Icon
                    size="26"
                    type="md-shirt"
                    class="workplatform-lock-icon"
                  />

                  <span slot="title">切换主题</span>
                  <div slot="content">
                    <RadioGroup
                      v-model="theme"
                      @on-change="handleChangeTheme"
                    >
                      <Radio
                        label="white"
                        border
                      >月光白</Radio>
                      <Radio
                        label="dark"
                        border
                      >暗夜黑</Radio>
                    </RadioGroup>
                  </div>
                </Poptip>
              </div>
            </i-col>
            <i-col :span="(publisherArray.length>1?8:12)">
              <Tooltip
                content="退出登录"
                placement="top-start"
                class="workplatform-lock"
              >
                <Icon
                  size="24"
                  type="md-power"
                  class="workplatform-lock-icon"
                  @click="handleLogout"
                />
              </Tooltip>
            </i-col>
          </Row>

          <div class="remark">{{ version }}</div>
        </div>
      </Sider>

      <Layout>
        <Content>
          <router-view></router-view>
        </Content>
      </Layout>
    </Layout>

    <Modal
      v-model="switchPublisherModal"
      :footer-hide="true"
      width="800"
    >
      <p
        slot="header"
        class="text-center"
      >切换运营方</p>
      <Row :gutter="8">
        <i-col
          span="12"
          class="m-b-5"
          v-for="item in publisherArray"
          :key="'p_' + item.id"
        >
          <a
            class="text-center platform"
            :class="defaultPublisherId === item.id ? 'platform-actived' : ''"
            href="javascript:void(0)"
            @click="handleSwitchPublisher(item)"
          >
            <h2>{{ item.name }}</h2>
          </a>
        </i-col>
      </Row>
    </Modal>

    <update-notice />
  </div>
</template>

<script>
import { getStorage, setStorage } from '@/utils/storage'
import updateNotice from './UpdateNotice.vue'
import { saveinstance } from '@/api/sys/event'
const packages = require('../../../package.json')

export default {
  components: {
    updateNotice
  },
  data () {
    return {
      // menuList: [],
      version: '',
      platformMenuArray: getStorage('userMenus') || [],
      platformMenu: getStorage('userShowMenu') || {},
      switchPlatformModal: false,
      switchPublisherModal: false,
      switchMenuModal: false,
      defaultPublisherId: this.$store.getters.token.userInfo.publisherId,
      publisherArray: this.$store.getters.token.userInfo.publishers,
      menuTheme: 'dark',
      theme: 'dark'
    }
  },
  computed: {
    menuList () {
      const currentMenuArray = this.$store.state.sys.menuArray
      // 北京轨交需要前置运维菜单
      if (this.platformMenu.platformId === 9 && this.$store.getters.token.userInfo.publisherId === 21) {
        const topMenuIndex = currentMenuArray.findIndex(x => x.meta.title === '运维')
        // 如果运维菜单未排在第一位，那么执行位置交换
        if (topMenuIndex > 0) {
          currentMenuArray[topMenuIndex] = currentMenuArray.splice(0, 1, currentMenuArray[topMenuIndex])[0]
          this.updateDefaultMenu(currentMenuArray)
        }
      }
      return currentMenuArray
    },
    // showMenuList () {
    //   return this.menuList
    // },
    defalutMenu: {
      get () {
        return this.$store.state.sys.currentMenu
      },
      set (val) {
        this.$store.commit('set_current_menu', val)
      }

    },
    openNames: {
      get () {
        return this.$store.state.sys.openNames
      },
      set (val) {
        this.$store.commit('set_open_names', val)
      }
    }
  },
  created () {
    let currentVersion = 'v ' + packages.version
    if (process.env.VUE_APP_BUILD === 'staging') {
      currentVersion += ' 测试版'
    } else if (process.env.VUE_APP_BUILD !== 'prod') {
      currentVersion += ' 开发版'
    }
    this.version = currentVersion

    this.initData()
  },
  methods: {
    initData () {
      this.defalutMenu = this.defalutMenu || this.menuList[0].children[0].meta.menuId
      this.saveinstance()

      // 设置主题颜色的值
      this.$parent.loadTheme()
      this.theme = getStorage('oohforce-workbench-theme') || 'dark'
      this.menuTheme = this.theme === 'white' ? 'light' : this.theme
    },
    handleLogout () {
      let loginAddress = '/login'
      if (process.env.NODE_ENV === 'production') {
        loginAddress = process.env.VUE_APP_LOGIN_ADDRESS
        // 生产环境需要跳转至不同的登录页
        if (process.env.VUE_APP_BUILD === 'prod') {
          if (this.defaultPublisherId === 13) {
            loginAddress = process.env.VUE_APP_LOGIN_ADDRESS2
          } else if (this.defaultPublisherId === 26) {
            loginAddress = process.env.VUE_APP_LOGIN_ADDRESS3
          }
        }
      }

      this.$store.dispatch('LogOut').then(res => {
        // 清理当前打开导航栏
        // this.$store.commit('SET_MENUGROUP', '')
        // this.$store.commit('set_current_menu', {})
        location.href = loginAddress
      })
    },
    // 主菜单选中事件
    handleChangeMenu (name) {
      // name为主菜单id
      this.defalutMenu = name
      // 获取选中菜单的组标识
      const selectedMenu = this.menuList.find(x => x.children.some(c => c.meta.menuId === name))
      this.openNames = selectedMenu ? [selectedMenu.meta.group] : []

      this.saveinstance()
    },
    handleSwitchPublisher (item) {
      if (item.id !== this.defaultPublisherId) {
        // 更新菜单权限
        this.$store.dispatch('changepublisher', { publisherId: item.id }).then(() => {
          this.showMessage = false
          this.switchPublisherModal = false
          window.location.href = '/'
        }).catch((error) => {
          this.showMessage = true
          this.switchPublisherModal = false
          this.message = error
        })
      }
    },
    getAuthSignData (sourceData, targetData = []) {
      sourceData.forEach(item => {
        if (item.children && item.children.length) {
          if (item.code !== '') {
            targetData.push(item.code)
          }
          this.getAuthSignData(item.children, targetData)
        } else {
          if (item.code !== '') {
            targetData.push(item.code)
          }
        }
      })
    },
    // 调用埋点
    saveinstance () {
      const data = {
        code: 'menu',
        name: '菜单'
      }
      // 获取选中菜单的信息
      const currentMenuInfo = this.menuList.find(x => this.openNames.includes(x.meta.group))
      const childMenuInfo = currentMenuInfo ? currentMenuInfo.children.find(x => x.meta.menuId === this.defalutMenu) : null
      data.data = JSON.stringify({
        relateId: this.defalutMenu,
        name: childMenuInfo ? childMenuInfo.meta.title : '',
        platformId: this.platformMenu.platformId,
        platformName: this.platformMenu.platformName
      })
      saveinstance(data).then(res => {
      })
    },
    /**
     * 切换平台
     * @param item 参数
     */
    handleSwitchPlatform (item) {
      if (item.platformId !== this.platformMenu.platformId) {
        // 更新菜单权限
        var auths = []
        this.getAuthSignData(item.menuList, auths)
        setStorage('userAuth', auths)
        setStorage('userShowMenu', item)
        this.$store.commit('set_current_menu', 0)
        window.location.href = '/'
      }
    },
    updateDefaultMenu (currentMenuArray) {
      this.defalutMenu = currentMenuArray[0].children[0].meta.menuId
      this.openNames = currentMenuArray[0].meta.group
      this.$router.push({ name: currentMenuArray[0].children[0].name })
    },
    // handleGoHome () {
    //   this.$router.push('/')
    // },
    handleChangeTheme () {
      setStorage('oohforce-workbench-theme', this.theme)
      // 立即执行主题切换
      this.$parent.loadTheme()
    }
  }
}
</script>
