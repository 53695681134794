import { getStorage, setStorage } from '@/utils/storage'
const sys = {
  state: {
    menuArray: [],
    currentMenu: getStorage('currentMenu') || 0,
    openNames: getStorage('openNames') || [],
    showLeftBlock: true,
    showRightBlock: true,
    showBottomBlock: true,
    isShowMap: true,
    bottomHeight: '300px',
    leftComponent: '',
    rightComponent: '',
    bottomComponent: '',
    mapComponent: '',
    linkTagArray: [], // 标签链接集合
    linkRouterMaps: new Map(), // 标签链接路由
    activedTagName: '' // 激活的tag标签名称
  },
  mutations: {
    set_menu_array: (state, data) => {
      state.menuArray = data
    },
    set_current_menu: (state, data) => {
      setStorage('currentMenu', data)
      state.currentMenu = data
    },
    set_open_names: (state, data) => {
      setStorage('openNames', data)
      state.openNames = data
    },
    set_show_left_block: (state, data) => {
      state.showLeftBlock = data
    },
    set_show_right_block: (state, data) => {
      state.showRightBlock = data
    },
    set_show_bottom_block: (state, data) => {
      state.showBottomBlock = data
    },
    set_show_map: (state, data) => {
      state.isShowMap = data
    },
    set_bottom_height: (state, data) => {
      state.bottomHeight = data
    },
    set_left_component: (state, data) => {
      state.leftComponent = data
    },
    set_right_component: (state, data) => {
      state.rightComponent = data
    },
    set_bottom_component: (state, data) => {
      state.bottomComponent = data
    },
    set_map_component: (state, data) => {
      state.mapComponent = data
    },
    set_link_tag_array: (state, data) => {
      if (!data) {
        state.linkTagArray = []
      } else {
        if (data instanceof Array) {
          state.linkTagArray = data
        } else {
          // 如果没有包含这条数据，那么新增，否则替换
          if (!state.linkTagArray.some(x => x.key === data.key)) {
            state.linkTagArray.push(data)
          } else {
            // 目前仅修改名称
            state.linkTagArray.find(x => x.key === data.key).value = data.value
          }
        }
      }
    },
    set_link_router_maps (state, data) {
      if (!data) {
        state.linkRouterMaps = new Map()
      } else {
        if (data instanceof Map) {
          state.linkRouterMaps = data
        } else {
          state.linkRouterMaps.set(data[0], data[1])
          // if (!state.linkRouterMaps.get(data[0])) {
          //   state.linkRouterMaps.set(data[0], data[1])
          // }
        }
      }
    },
    set_actived_tag_name (state, data) {
      state.activedTagName = data
    }
  },
  actions: {

  }
}

export default sys
