import axios from 'axios'
import { Notice } from 'view-design'
import { getStorage, setStorage } from './storage'
const qs = require('qs')

window.axiosCancel = []

// 创建axios实例
const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL_V2, // API地址
  timeout: 20000, // 请求超时
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
  withCredentials: false // 允许携带cookie
})

const CancelToken = axios.CancelToken
// const source = CancelToken.source()

// request拦截器
service.interceptors.request.use(config => {
  // Do something before request is sent
  config.cancelToken = new CancelToken(cancel => {
    window.axiosCancel.push({ cancel })
  })

  const authToken = getStorage('ooh-token', true)
  const refreshToken = getStorage('refresh-token', true)
  const nowTime = new Date().valueOf()
  const userShowMenu = getStorage('userShowMenu')
  if (userShowMenu) {
    config.headers.platformId = userShowMenu.platformId
  }

  if (authToken) {
    config.headers['ooh-auth'] = authToken.data
    // config.cancelToken = source.token

    const surplusMinutes = (authToken.expirse - nowTime) / (1000 * 60)

    // 登录时长50~180分钟内有请求，刷新token，并更新旧token，排除推出登录接口
    if (surplusMinutes > 0 && surplusMinutes <= 130 && config.url !== '/ooh-os/v1/logout') {
      axios({
        baseURL: process.env.VUE_APP_API_URL_V2, // API地址
        url: '/ooh-os/v1/refreshtoken',
        timeout: 10000, // 请求超时
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'ooh-auth': authToken.data
        },
        withCredentials: false, // 允许携带cookie
        method: 'post',
        data: qs.stringify({
          refresh_token: refreshToken.data
        })

      }).then(res => {
        // 刷新token成功
        if (res.data && !res.data.errcode) {
          // 更新旧token缓存
          setStorage('ooh-token', `${res.data.token_type} ${res.data.access_token}`, 10800)
          setStorage('refresh-token', res.data.refresh_token, 10800)
        }
      })
    }
  }

  return config
}, error => {
  Promise.reject(error)
})

// respose拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if (!res) { return null }

    // 显示异常信息
    if (res.errcode && ![0, 200].includes(res.errcode)) {
      Notice.warning({ title: '操作提示', desc: res.errmsg })
      Promise.reject(res)
    } else {
      return response.data
    }
  },
  /**
  * 下面的注释为通过response自定义code来标示请求状态，当code返回如下情况为权限有问题，登出并返回到登录页
  * 如通过xmlhttprequest 状态码标识 逻辑可写在下面error中
  */
  //  const res = response.data;
  //     if (res.code !== 20000) {
  //       Message({
  //         message: res.message,
  //         type: 'error',
  //         duration: 5 * 1000
  //       });
  //       // 50008:非法的token; 50012:其他客户端登录了;  50014:Token 过期了;
  //       if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
  //         MessageBox.confirm('你已被登出，可以取消继续留在该页面，或者重新登录', '确定登出', {
  //           confirmButtonText: '重新登录',
  //           cancelButtonText: '取消',
  //           type: 'warning'
  //         }).then(() => {
  //           store.dispatch('FedLogOut').then(() => {
  //             location.reload();// 为了重新实例化vue-router对象 避免bug
  //           });
  //         })
  //       }
  //       return Promise.reject('error');
  //     } else {
  //       return response.data;
  //     }
  error => {
    const err = error.response

    // 服务器超时过期， 此处无法获取store和process.env.VUE_APP_LOGIN_ADDRESS的值， 需要从router中获取
    if (err.data.errcode && err.data.errcode === 401) {
      let loginAddress = '/login'

      if (process.env.NODE_ENV === 'production') {
        loginAddress = process.env.VUE_APP_LOGIN_ADDRESS
        // 生产环境需要跳转至不同的登录页
        if (process.env.VUE_APP_BUILD === 'prod') {
          const token = getStorage('workbench-token') // 获取存在的TOKEN

          if (token.userInfo && token.userInfo.publisherId === 13) {
            loginAddress = process.env.VUE_APP_LOGIN_ADDRESS2
          } else if (token.userInfo && token.userInfo.publisherId === 26) {
            loginAddress = process.env.VUE_APP_LOGIN_ADDRESS3
          }
        }
      }

      window.location.href = loginAddress
    } else {
      Notice.error({ title: '服务器请求错误', desc: err ? err.data.errmsg : error.message })
      // if (err.status === 400) {
      //   Notice.warning({ desc: err.data.errmsg })
      // } else {
      //   Notice.error({ title: '服务器请求错误', desc: error.message })
      // }
      return Promise.reject(error)
    }
  }
)

export default service
