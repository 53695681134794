import request from '@/utils/requestV2'
const qs = require('qs')

// 用户名密码登录
export function login (data) {
  return request({
    url: '/ooh-os/v1/login',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 根据企业微信Userid获取用户信息
export function loginByQYUserId (data) {
  const requestData = {
    UserId: data.Uid,
    cropId: data.AppId ? data.AppId : 'wwe7a007eb6a2f2994'
  }

  return request({
    url: '/ooh-os/v1/loginbyqyuserid',
    method: 'post',
    data: qs.stringify(requestData)
  })
}
// 根据微信Userid获取用户信息
export function loginByWxUserId (data) {
  const requestData = {
    UserId: data.Uid,
    appId: data.AppId
  }

  return request({
    url: '/ooh-os/v1/loginByWxUserId',
    method: 'post',
    data: qs.stringify(requestData)
  })
}
// 根据Userid获取用户信息
export function loginByDingTalk (data) {
  const requestData = {
    UserId: data.Uid,
    appId: data.AppId
  }

  return request({
    url: '/ooh-os/v1/dingTalk/loginByDingTalk',
    method: 'post',
    data: qs.stringify(requestData)
  })
}
// 用户帐号退出
export function logout (data) {
  return request({
    url: '/ooh-os/v1/logout',
    method: 'post',
    data: qs.stringify(data)
  })
}

/**
 * refreshtoken
 * @param {Object} data
 */
export function refreshToken (data) {
  return request({
    url: '/ooh-os/v1/refreshtoken',
    method: 'post',
    data: qs.stringify(data)
  })
}
