import request from '@/utils/requestV2'
const qs = require('qs')

// 获取用户菜单V3
export function getMenuByUser (data) {
  return request({
    url: '/ooh-os/v1/menu/v3/getbyuser',
    method: 'post',
    data: qs.stringify(data)
  })
}
