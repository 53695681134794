import request from '@/utils/requestV2'
const qs = require('qs')

export function getUserById (query) {
  return request({
    url: '/ooh-os/v1/user/getuser',
    method: 'post',
    params: query
  })
}

// 删除用户
export function deleteUser (data) {
  return request({
    url: '/ooh-os/v1/user/deleteuser',
    method: 'post',
    params: data
  })
}

export function addUser (data) {
  data.roleIds = JSON.stringify(data.roleArr)
  return request({
    url: '/ooh-os/v1/user/adduser',
    method: 'post',
    data: qs.stringify(data)
  })
}

export function updateUser (data) {
  data.roleIds = JSON.stringify(data.roleArr)
  return request({
    url: '/ooh-os/v1/user/updateuser',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 修改密码 返回值： 1成功 2 旧密码错误
export function resetPassword (data) {
  return request({
    url: '/ooh-os/v1/user/resetpwd',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 变更用户最后阅读文档
export function changeLastReadWord (data) {
  return request({
    url: '/ooh-os/v1/user/changelastreadword',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 权限相关
export function getPermissions () {
  return request({
    url: '/v1/permission/getpermissions',
    method: 'get'
  })
}

// 解绑企业微信
export function unbindqyuser (data) {
  return request({
    url: '/ooh-os/v1/user/unbindqyuser',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 分页获取用户
export function getUserPage (data) {
  return request({
    url: '/ooh-os/v1/user/getuserpage',
    method: 'post',
    data: qs.stringify(data)
  })
}
// 变更当前登录租户
export function changePublisher (data) {
  return request({
    url: '/ooh-os/v1/user/changepublisher',
    method: 'post',
    data: qs.stringify(data)
  })
}

// 获取平台/常规/公司/媒介主用户绑定关系列表
export function getUserinfoPage (data) {
  return request({
    url: '/ooh-os/v1/user/getuserinfopage',
    method: 'post',
    data: qs.stringify(data)
  })
}
