import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import { Notice } from 'view-design'
import { setStorage, getStorage } from '@/utils/storage'
// import { getMenuByUser } from '@/api/os/menu'

Vue.use(VueRouter)

var importComponent = (filePath) => () => import('@/views/' + filePath + '.vue')

const commonViewComponents = {
  LayoutView: require('@/views/layout/Layout_V2').default,
  IndexRouterView: require('@/components/common/IndexRouter').default
}
// 开放路由
const publicRouters = [
  { path: '/login', name: 'login', meta: { title: '登录' }, component: importComponent('Login') },
  { path: '/DataV-SVG/:menu/:querys', name: 'situationMap', meta: { title: '态势地图' }, component: importComponent('situation/components/SvgLine') },
  { path: '/DataV-Heat/:menu/:querys', name: 'situationHeat', meta: { title: '热力地图' }, component: importComponent('situation/StationHeat') },
  { path: '/DataV-MBMap/:menu/:querys', name: 'situationMBMap', meta: { title: '小微蜂巢图' }, component: importComponent('situation/MicroBussniesMap') }
]

// 解决重复点击路由报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  isAddDynamicMenuRoutes: false, // 是否已加入动态菜单
  routes: publicRouters
})

router.beforeEach((to, from, next) => {
  const urlToken = to.query.token // 传入的登录信息
  const token = getStorage('workbench-token') // 获取存在的TOKEN
  const authToken = getStorage('ooh-token', true)

  // 登录超时处理
  const nowTime = new Date().valueOf()
  const cancelArr = window.axiosCancel

  store.commit('set_left_component', null)
  store.commit('set_bottom_component', null)
  store.commit('set_right_component', null)
  store.commit('set_map_component', null)

  if (to.path === '/') {
    const userCurMenu = getStorage('userShowMenu')
    if (userCurMenu) {
      store.commit('set_open_names', userCurMenu.menuList.length ? [userCurMenu.menuList[0].id] : [])
      store.commit('set_current_menu', userCurMenu.menuList.length ? (userCurMenu.menuList[0].children.length ? userCurMenu.menuList[0].children[0].id : 0) : null)
    }
  } else if (to.path === '/login' && process.env.NODE_ENV === 'production') {
    // 版本发布后，不允许直接进入login界面
    window.location.href = getReturnLoginAddress(token)
  }

  // ----------------客户端超时过期-----------------
  if (authToken && authToken.expirse <= nowTime) {
    let timeoutAddress = '/login'
    if (process.env.NODE_ENV === 'production') {
      timeoutAddress = getReturnLoginAddress(token)
    }

    // 开始执行跳转
    if (from.path !== '/') {
      // 清除缓存
      Notice.error({ title: '登录已过期' })
      store.commit('SET_TOKEN', '')
      store.commit('SET_ROLES', [])
      // 指定部分属性值不需要清除
      const version = window.localStorage.getItem('oohforce-workbench')
      const theme = getStorage('oohforce-workbench-theme').toString() || 'dark'

      // 清空所有localStorage
      window.localStorage.clear()
      window.localStorage.setItem('oohforce-workbench', version)
      setStorage('oohforce-workbench-theme', theme)
      // 取消所有请求
      cancelArr.forEach((ele, index) => {
        ele.cancel()
        delete window.axiosCancel[index]
      })
      // 跳转
      window.location.href = timeoutAddress
    }
  }

  // 从官网登录进入
  if (urlToken && process.env.NODE_ENV === 'production') {
    // 执行产品版登录操作
    const CryptoJS = require('crypto-js')
    const bytes = CryptoJS.AES.decrypt(urlToken.toString(), process.env.VUE_APP_KEY)
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))

    let errorReturnAddress = process.env.VUE_APP_LOGIN_ADDRESS
    // 微信扫码登录
    if (decryptedData.Uid !== undefined) {
      if (decryptedData.website === 1) { // 青岛
        errorReturnAddress = process.env.LOGIN_ADDRESS2
      } else if (decryptedData.website === 2) { // 云南京建
        errorReturnAddress = process.env.LOGIN_ADDRESS3
      }

      store.dispatch('LoginByScan', decryptedData).then(() => {
        next({ path: '/' })
      }).catch(() => {
        window.location.href = errorReturnAddress
      })
    } else if (decryptedData.username !== undefined && decryptedData.password !== undefined) {
      // 用户名密码登录
      store.dispatch('LoginByUsername', decryptedData).then(() => {
        next({ path: '/' })
      }).catch(() => {
        window.location.href = errorReturnAddress
      })
    } else {
      window.location.href = errorReturnAddress
    }
  } else if (authToken && token) {
    // 如果已经包含TOKEN的用户信息
    if (token.userInfo.userId !== store.getters.token.userInfo.userId) {
      // 打开新标签页更换用户登录，更新旧标签页VueX状态，并强制刷新当前页，重新生成菜单栏
      store.commit('SET_TOKEN', token)
      // 内部执行router跳转
      location.href = to.path
    } else {
      // 添加动态菜单路由
      if (router.options.isAddDynamicMenuRoutes || publicRouters.some(x => x.path === to.path)) {
        next()
      } else {
        const userShowMenu = getStorage('userShowMenu')
        if (userShowMenu && userShowMenu.menuList.length >= 1) {
          fnDynamicMenuRouters(userShowMenu.menuList)
          router.options.isAddDynamicMenuRoutes = true
          const toPath = to.path === '/' ? userShowMenu.menuList[0].children[0].path : to.path
          next({ path: toPath })
        } else {
          const customRouter = {
            path: '/NoRole',
            component: commonViewComponents.LayoutView,
            meta: {
              title: '未配置权限',
              group: -1,
              category: 1
            },
            children: [{
              path: '/NoRole',
              name: 'norole',
              component: importComponent('layout/NoRole'),
              meta: {
                menuId: -1,
                title: '首页',
                group: -1,
                category: 1
              }
            }]
          }

          router.addRoute('未配置权限', customRouter)
          router.options.isAddDynamicMenuRoutes = true
          setStorage('dynamicMenus', [customRouter])
          store.commit('set_menu_array', [customRouter])
          next({ path: '/NoRole' })
          // Notice.error({ desc: '无权限登录' })
          // setTimeout(() => {
          //   if (process.env.NODE_ENV === 'production') {
          //     window.location.href = loginAddress
          //   } else {
          //     next({ name: 'login' })
          //   }
          // }, 2000)
        }
      }
    }
  } else {
    if (process.env.NODE_ENV === 'production') {
      window.location.href = getReturnLoginAddress(token)
    } else if (to.path === '/login') {
      next()
    } else {
      next({ name: 'login' })
    }
  }
})

/**
 * 动态菜单路由组装
 * @param {Array} menuList 菜单列表
 * @param {Array} routers 路由集合
 */
function fnDynamicMenuRouters (menuList = [], routers = []) {
  // try {
  for (let i = 0; i < menuList.length; i++) {
    const r = {
      path: menuList[i].path,
      component: commonViewComponents[menuList[i].template] ? commonViewComponents[menuList[i].template] : commonViewComponents.LayoutView,
      meta: {
        title: menuList[i].title,
        icon: menuList[i].icon,
        group: menuList[i].id,
        category: menuList[i].category
      },
      children: []
    }
    if (menuList[i].children && menuList[i].children.length >= 1) {
      // 组装子菜单路由信息
      fnChildrenRouters(menuList[i].children, r, menuList[i].id)
    }
    routers.push(r)
    router.addRoute(r.name, r)
  }
  setStorage('dynamicMenus', routers)
  store.commit('set_menu_array', routers)
  // router.addRoutes(routers)
  // } catch (e) {
  //   throw e
  // }
}

/**
 * 子菜单生成
 * @param {Array} menuList 菜单列表
 * @param {Object} router 当前父级菜单对象
 */
function fnChildrenRouters (menuList = [], router, parentId) {
  for (let i = 0; i < menuList.length; i++) {
    // 不处理按钮类型数据
    if (menuList[i].category === 3) {
      return false
    }
    const child = {
      path: menuList[i].path,
      name: menuList[i].router || '',
      component: menuList[i].filePath ? importComponent(menuList[i].filePath) : commonViewComponents[menuList[i].template],
      meta: {
        menuId: menuList[i].id,
        title: menuList[i].title,
        icon: menuList[i].icon ? menuList[i].icon : '',
        group: parentId,
        category: menuList[i].category ? menuList[i].category : 1
      }
    }
    if (menuList[i].alias) {
      child.alias = menuList[i].alias
    }
    if (menuList[i].keepAlive) {
      child.meta.keepAlive = menuList[i].keepAlive
    }
    // 存在子菜单进行递归调用，生成路由信息
    if (menuList[i].children && menuList[i].children.length >= 1) {
      child.children = []
      fnChildrenRouters(menuList[i].children, child, parentId)
    }
    router.children.push(child)
  }
}

// 编译发布后，动态获取登录地址
function getReturnLoginAddress (token) {
  let loginAddress = process.env.VUE_APP_LOGIN_ADDRESS

  // 生产环境需要跳转至不同的登录页
  if (process.env.VUE_APP_BUILD === 'prod') {
    if (token.userInfo && token.userInfo.publisherId === 13) {
      loginAddress = process.env.VUE_APP_LOGIN_ADDRESS2
    } else if (token.userInfo && token.userInfo.publisherId === 26) {
      loginAddress = process.env.VUE_APP_LOGIN_ADDRESS3
    }
  }

  return loginAddress
}

export default router
