import Vue from 'vue'
import App from './App.vue'
import router from './router/index_v2'
import store from './store'
import VueAMap from 'vue-amap'
import ViewUI from 'view-design'
import locale from 'view-design/dist/locale/zh-CN'
import Viewer from 'v-viewer'

import './assets/css/common.css'
import 'viewerjs/dist/viewer.css'
import { isAuth } from './utils/permission'

Vue.config.productionTip = false
Vue.use(ViewUI, { locale })
Vue.use(VueAMap)
Vue.use(Viewer)
// 挂载全局
Vue.prototype.isAuth = isAuth
// 初始化vue-amap
VueAMap.initAMapApiLoader({
  key: 'cde454b5669772a9d631bec9d26c8c1d', // 高德的key
  v: '2.0'
  // 插件集合
  // plugin: ['AMap.Weather', 'AMap.HeatMap']
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// window.onbeforeunload = function () {
//   console.log(localStorage.getItem('userShowMenu'))
//   return 1
// }
