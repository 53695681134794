<style scoped>
.file-preview{
  height: 800px;
  overflow-y: auto;
}
</style>

<template>
  <Modal v-model="modalReviewNotice" footer-hide :mask-closable="false" :closable="false" :styles="{top: '50px'}" width="1000">
    <p class="text-center p-b-5">
      <Button type="info" style="width:200px" size="small" :disabled="modalTimer>0" @click="handleCloseNotice">
        <template v-if="modalTimer>0">
          本窗口可在 <span class="text-red">{{modalTimer}}</span>秒 后点击此处关闭
        </template>
        <span v-else>关闭更新公告</span>
      </Button>
    </p>
    <div class="file-preview">
      <vue-office-docx
        :src="fileBuffer"
        style="height: 100vh;"
        @rendered="renderedHandler"
        @error="errorHandler"
      />
      <Spin fix v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>文件加载中</div>
      </Spin>
      <!-- <div ref="noticeFilePreview"></div> -->
    </div>
  </Modal>
</template>

<script>
import axios from 'axios'
import { getStorage } from '@/utils/storage'
import { getNewest } from '@/api/sys/file'
// 引入VueOffice插件， 此处不能使用docx-preview， 因为自动编译会报路劲异常
// import { renderAsync } from 'docx-preview'
import VueOfficeDocx from '@vue-office/docx'

export default {
  components: {
    VueOfficeDocx
  },
  data () {
    return {
      modalReviewNotice: false,
      modalTimer: 0,
      lastReadWordId: -1,

      spinShow: false,
      fileBuffer: null // 文件二进制流
    }
  },
  mounted () {
    getNewest({ publisherId: this.$store.getters.token.userInfo.publisherId }).then(res => {
      let currentNotice = null
      // 获取最新的更新公告
      if (res.length === 1) {
        currentNotice = res[0]
      } else if (res.length === 2) {
        currentNotice = res.sort((a, b) => (a.id - b.id))[1]
      }

      if (currentNotice && this.$store.getters.token.userInfo.lastReadWord !== currentNotice.id) {
        this.lastReadWordId = currentNotice.id
        this.reviewUpdateNotice(currentNotice)
      }
    })
  },
  methods: {
    reviewUpdateNotice (notice) {
      this.spinShow = true

      axios({
        method: 'get',
        responseType: 'blob', // 设置响应文件格式
        url: process.env.VUE_APP_API_URL_V2 + '/ooh-sys/v1/updateinfo/getbytes?id=' + notice.id,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'ooh-auth': getStorage('ooh-token')
        }
      }).then(response => {
        // 设置窗口可关闭倒计时10秒
        this.modalReviewNotice = true
        this.modalTimer = 10
        var intervalID = null
        var _self = this
        intervalID = setInterval(function () {
          if (_self.modalTimer > 0) {
            _self.modalTimer--
          } else {
          // 取消无限循环
            clearInterval(intervalID)
          }
        }, 1000)

        // 开始读取文档二进制流
        const fileReader = new FileReader()
        fileReader.readAsArrayBuffer(response.data)
        fileReader.onload = () => {
          this.fileBuffer = fileReader.result
          this.spinShow = false
        }
        // renderAsync(data, this.$refs.noticeFilePreview) // 渲染到页面预览
      })
    },
    renderedHandler () {
      this.spinShow = false
    },
    errorHandler () {
      this.spinShow = false
    },
    handleCloseNotice () {
      this.modalReviewNotice = false
      // 更新当前用户的信息
      const postData = {
        id: this.$store.getters.token.userInfo.userId,
        lastReadWord: this.lastReadWordId
      }
      this.$store.dispatch('changeLastReadWord', postData)
    }
  }
}
</script>
